import {load} from 'recaptcha-v3'

export const useGoogleRecaptcha = () => {
    if (!import.meta.env.VITE_NOCAPTCHA_SITEKEY) {
        console.warn('reCAPTCHA is disabled. Please provide VITE_NOCAPTCHA_SITEKEY in .env file');
        return {getRecaptchaToken: () => Promise.resolve('')};
    }
    const getRecaptchaToken = async (action) => {
        const recaptcha = await load(import.meta.env.VITE_NOCAPTCHA_SITEKEY, {
            autoHideBadge: true,
            useRecaptchaNet: true
        })
        return await recaptcha.execute(action)
    }

    return {getRecaptchaToken}
}
